














import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'text-field-email'})
export default class TextFieldEmail extends Vue {
  @Prop() value
  @Prop({type: Boolean, default: false}) readonly
  @Prop({type: String, required: false}) label
  @Prop({type: String, default: ''}) fieldAttribute
  @Prop({type: String, default: 'off'}) autocomplete
  @Prop({type: Array, default: () => []}) rules
  @Prop({type: String, default: 'text-field'}) clazz

  get email() {
    return this.value
  }

  set email(value: string) {
    this.$emit('input', value.toLowerCase())
  }
}
